import React, { useContext } from 'react'
import  {useNavigate}  from 'react-router-dom';
import { PaymentMethodWrapper, AddCardWithTextBox, CardImgBg, TextWithButtonBox, CardImg, TextForAddCard, AddCardButtonBox, AddCardButton, DeleteCardButton } from '../Styles/PaymentMethod.Styles';
import { AddCardCounter } from '../Context/context';
import CardImage from '../Assets/CardVinDigits.png'
import { useTranslation } from 'react-i18next';

const PaymentMethod = () => {
    const { openAddCard, setOpenAddCard } = useContext(AddCardCounter);
    const navigate = useNavigate();
    const CardWindowHandler = () => {
        setOpenAddCard(true);
    };
    const { t } = useTranslation();

    const storedCardData = JSON.parse(localStorage.getItem("cardData"));
    const { name, number, cvv, billingZipCode, expirationMonth, expirationYear } = storedCardData || {};
    const maskedCardNumber = number ? "************" + number.slice(-4) : null;

    const deleteCardHandler = () => {
        localStorage.removeItem("cardData");
        window.location.href = "UserPanel";
    };


    return (
        <PaymentMethodWrapper>
            {storedCardData ? (
                <AddCardWithTextBox>
                    <CardImgBg>
                        <CardImg src={CardImage} />
                    </CardImgBg>
                    <TextWithButtonBox>
                        <TextForAddCard>
                            {`Card ending in ${maskedCardNumber}`}
                        </TextForAddCard>
                        <AddCardButtonBox>
                        <AddCardButton onClick={CardWindowHandler}>
                               {t(" Change Card")}
                            </AddCardButton>
                            <DeleteCardButton onClick={deleteCardHandler}>
                               {t(" Delete Card")}
                            </DeleteCardButton>
                        </AddCardButtonBox>
                    </TextWithButtonBox>
                </AddCardWithTextBox>
            ) : (
                <AddCardWithTextBox>
                    <CardImgBg>
                        <CardImg src={CardImage} />
                    </CardImgBg>
                    <TextWithButtonBox>
                        <TextForAddCard>
                            {t("For payments please use Visa or Mastercard.")}
                        </TextForAddCard>
                        <AddCardButtonBox>
                            <AddCardButton onClick={CardWindowHandler}>
                                {t("Add Card")}
                            </AddCardButton>
                        </AddCardButtonBox>
                    </TextWithButtonBox>
                </AddCardWithTextBox>
            )}
        </PaymentMethodWrapper>
    );
};

export default PaymentMethod;