import React, { useState, useContext } from "react";
import {
  MainWrapper,
  Close,
  LangBoxKa,
  LangBoxEs,
  LangBoxUk,
  LangBoxTr,
  LangBoxZh,
  LangBoxRu,
  LangBoxHi,
  EngLang,
  ForeignLang,
} from "../Styles/LangSlider.Styles";
import { LangContext } from "../Context/context";
import i18next from "i18next";

const LangSlider = () => {
  const { lang ,setLang } = useContext(LangContext);

  const xHandler = () => {
    setLang(lang - 1);
  };

  const updateLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  return (
    <>
      {lang === 1 ? (
        <MainWrapper>
          <Close onClick={xHandler}>X</Close>
          <LangBoxKa onClick={() => {xHandler(); updateLanguage('ka')}}>
            <EngLang>Georgian</EngLang>
            <ForeignLang>ქართული</ForeignLang>
          </LangBoxKa>
          <LangBoxEs onClick={() => {xHandler(); updateLanguage('es')}}>
            <EngLang>México</EngLang>
            <ForeignLang>Español</ForeignLang>
          </LangBoxEs>
          <LangBoxUk onClick={() => {xHandler(); updateLanguage('uk')}}>
            <EngLang>Ukrainian</EngLang>
            <ForeignLang>українська</ForeignLang>
          </LangBoxUk>
          <LangBoxTr onClick={() => {xHandler(); updateLanguage('tr')}}>
            <EngLang>Turkish</EngLang>
            <ForeignLang>Türk</ForeignLang>
          </LangBoxTr>
          <LangBoxZh onClick={() => {xHandler(); updateLanguage('zh')}}>
            <EngLang>Hong Kong</EngLang>
            <ForeignLang>繁體中文</ForeignLang>
          </LangBoxZh>
          <LangBoxRu >
            <EngLang onClick={() => {xHandler(); updateLanguage('ru')}}>Russian</EngLang>
            <ForeignLang>русский</ForeignLang>
          </LangBoxRu>
          <LangBoxHi onClick={() => {xHandler(); updateLanguage('hi')}}>
            <EngLang>Indian</EngLang>
            <ForeignLang>भारतीय</ForeignLang>
          </LangBoxHi>
          <LangBoxHi onClick={() => {xHandler(); updateLanguage('en')}}>
            <EngLang>English</EngLang>
            <ForeignLang>Return To English</ForeignLang>
          </LangBoxHi>
        </MainWrapper>
      ) : null}
    </>
  );
};

export default LangSlider;
