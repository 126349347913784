import styled from 'styled-components';
import { Home } from "@styled-icons/boxicons-regular/Home"
import { ChevronDown } from "@styled-icons/entypo/ChevronDown"

export const MobileViewUserControlContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    // background-color: red;
    align-items: center;
    

`

export const DashIconForMoblieView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 15%;
    background-color: #EEEEEE;
    border-radius: 50px;
    margin-left: 5%;
`

export const TitleForMoblieView = styled.div`
    display: flex;
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    font-size: 1.25em;
    letter-spacing: 0.1em;
    color: black;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;

    `

export const ArrowForMoblieView = styled.div`
    display: flex;
    height: 100%;
    width: 15%;
`

export const SvgBox = styled.div`
    display: flex;
    height: 100%;
    width: 25%;
    align-items: center;
    justify-content: center;
`

export const MobileHomeIcon = styled(Home)`
    display: flex;
    height: 100%;
    color: black;
    width: 60%;
`
export const ArrowDown = styled(ChevronDown)`
    display: flex;
    height: 100%;
    color: black;
    width: 60%;

  
`

export const WrapperForMobileMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 10%;
    left:10%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 80vw;
    background: white;
    border: 1px solid lightgray;
    -webkit-box-shadow: -3px 0px 11px -1px rgba(0,0,0,0.55); 
     box-shadow: -3px 0px 11px -1px rgba(0,0,0,0.55);
     z-index: 9933 !important;
     border-radius: 20px;
     max-height: 600px;
     @keyframes AddCardWrapper {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    animation: AddCardWrapper 1s ease 0s 1 normal forwards;

    @media (max-width: 750px) {
        background: white;
        z-index: 9933;
    }

`;

export const CloseMobileMenu = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 10%;
    width: 90%;
    font-size: 2.6em;
    font-weight: bold;
    color: black;
`