import styled from 'styled-components'


export const ProfileSettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 90%;
    margin-top: 2.5%;

    @media(max-width: 750px) {
        display: flex;
        height: 90%;
    }

`
export const ProfileNameAddressPhoneBox = styled.div`
    display: flex;
    height: 33.333%;
    width: 100%;
   
    @media(max-width: 750px) {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
    }

`

export const ProfileEmailPassword = styled.div`
    display: flex;
    height: 33.333%;
    width: 100%;

    @media(max-width: 750px) {
        display: flex;
        justify-content: space-between;
    }

`

export const DeletAccountBox = styled.div`
    display: flex;
    height: 33.333%;
    width: 100%;

`

export const FullNameBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33.333%;

    @media(max-width: 750px) {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
    }
`

export const LabelForFullName = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #5c5e62;

   
`
export const ActualFullName = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
`
export const InputForFullName = styled.input`
    display: flex;
    height: 20%;
    width: 80%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
`
export const InputForAddress = styled.input`
    display: flex;
    height: 20%;
    width: 80%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;

`

export const InputForPhoneEdit = styled.input`
    display: flex;
    height: 20%;
    width: 80%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
`

export const InputForEmailEdit = styled.input`
    display: flex;
    height: 20%;
    width: 80%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
`
export const InputForPasswordEdit = styled.input`
    display: flex;
    height: 20%;
    width: 80%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
`

export const EditActualFullName = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #393C41;
    text-decoration: 2px black underline;
    text-underline-offset: 5px;
    cursor: pointer;
`

export const AddressBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33.333%;

    @media(max-width: 750px) {
        display: flex;
        width: 100%;
    }
`

export const LabelForAddress = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #5c5e62;
`
export const ActualAddress = styled.div`
    display: flex;
    height: 40%;
    width: 100%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
`

export const EditActualAddress = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #393C41;
    text-decoration: 2px black underline;
    text-underline-offset: 5px;
    cursor: pointer;
`

export const EditContactPhone = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #393C41;
    text-decoration: 2px black underline;
    text-underline-offset: 5px;
    cursor: pointer;
`


export const ContactPhoneBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33.333%;

    @media(max-width: 750px) {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }
`

export const LabelForPhone = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #5c5e62;
`
export const ActualPhoneNumber = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
`

export const EditActualPhoneNumber = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #393C41;
    text-decoration: 2px black underline;
    text-underline-offset: 5px;
    cursor: pointer;
`

export const EmailBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33.333%;
    @media(max-width: 750px) {
        display: flex;
        width: 60%;
    }
`

export const EmailLabel = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #5c5e62;
`
export const ActualEmail = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
    overflow: hidden;
`

export const EditActualEmail = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #393C41;
    text-decoration: 2px black underline;
    text-underline-offset: 5px;
    cursor: pointer;
`

export const PasswordBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33.333%;

    @media(max-width: 750px) {
        display: flex;
        width: 30%;
    }
`

export const LabelForPass = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #5c5e62;
`
export const ActualPass = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    font-weight: 500!important;
    color: #171a20;
`

export const EditActualPass= styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #393C41;
    text-decoration: 2px black underline;
    text-underline-offset: 5px;
    cursor: pointer;
`
export const DeleteAcountBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33.333%;
`
export const ActualDelete= styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 1.2em;
    color: #393C41;
    text-decoration: 2px black underline;
    text-underline-offset: 5px;
    cursor: pointer;
`