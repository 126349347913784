import styled from 'styled-components'


export const AddCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 25%;
    left:30%;
    bottom: 25%;
    transform: translate(-50%, -50%);
    height: 70vh;
    width: 50vw;
    background: white;
    max-width: 770px;
    border: 1px solid lightgray;
    -webkit-box-shadow: -3px 0px 11px -1px rgba(0,0,0,0.55); 
     box-shadow: -3px 0px 11px -1px rgba(0,0,0,0.55);
     z-index: 9933 !important;
     border-radius: 20px;
     max-height: 600px;
     @keyframes AddCardWrapper {
        0% {
            transform: scale(0);
        }
    
        100% {
            transform: scale(1);
        }
    }
   
    animation: AddCardWrapper 1s ease 0s 1 normal forwards;

    @media (max-width: 750px) {
        background: white;
        width: 90vw;
        top: 15%;
        left: 5%;
        bottom: 5%;
        z-index: 9933;
    }
}

`

export const CloseAddVCardBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 10%;
    width: 90%;
    font-size: 2.6em;
    font-weight: bold;
    color: black;
`

export const CloseAddVCardX = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 10%;
    font-size: 1.2em;
    font-weight: bold;
    color: black;
    cursor: pointer;
    animation: myAnim 2s ease 0s 1 normal forwards;
    @keyframes myAnim {
        0% {
            transform: rotate(0);
        }
    
        100% {
            transform: rotate(360deg);
        }
    }
`


export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
//   justify-content: center;
  align-items: center;
  height: 85%;
  width: 90%;
`;

export const StyledForm = styled.form`
  display: flex;
  height: 90%;
  width: 80%;
  flex-direction: column;
  align-items: center;
`;

export const StyledInput = styled.input`
  display: flex;
  border: none;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  height: 15%;
  width: 100%;
  background: #f5f5f5;
  font-weight: bold;
  max-char: 16;
  
`;


export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10%;
  width: 25%;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }

//   max-width: 380px;
//   max-height: 70px;

  @media (max-width: 750px) {
    height: 10%;
    width: 40%;
    min-width: 140px;
    min-height: 50px;
  }
`;