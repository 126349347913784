import React, { useContext, useState } from "react";
import {
  TokensContext,
  ActualVinNumber,
  UserPanelCompCounter,
  VinAmountError,
} from "../Context/context";

import {
  ErrorContainer,
  ErrorText,
  RefillButton,
} from "../Styles/ErrorForTokensAmount.Styles";

const ErrorMessage = () => {
  const { tokens } = useContext(TokensContext);
  const { actualVinNumber } = useContext(ActualVinNumber);
  const { openPanel, setOpenPanel } = useContext(UserPanelCompCounter);
  const { showVinAmountError, setShowVinAmountError } =
    useContext(VinAmountError);

  const onRefillClick = () => {
    setOpenPanel(3);
    setShowVinAmountError(false);
  };
  console.log("vinamoutnerror", showVinAmountError);
  return (
    <ErrorContainer>
      <ErrorText>
        You don't have enough tokens to view the VIN history. Please make sure
        you have enough tokens or check the provided VIN code: {actualVinNumber}
        . 
        Current token amount: {tokens}.
      </ErrorText>
      {/* <ErrorText>Please add funds to refill tokens amount {tokens}.</ErrorText> */}
      <RefillButton onClick={onRefillClick}>Click here to refill</RefillButton>
    </ErrorContainer>
  );
};

export default ErrorMessage;
