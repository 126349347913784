import React, { useState } from 'react';
import axios from 'axios';
import { ContactSupportWrapper, ContactForm, Input, TextArea, Button, ErrorMessage } from '../Styles/ContactSupport.Styles'
import { useTranslation } from 'react-i18next';


const ContactSupport = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [ticketNumber, setTicketNumber] = useState('');
    const [problem, setProblem] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Generate ticket number
        const ticketNum = Math.floor(Math.random() * 1000000);
    
        try {
            // Send email with form data
            await axios.post('http://localhost:3001/submit', {
                name,
                email,
                phoneNumber,
                ticketNumber: ticketNum, // pass the generated ticketNum directly
                problem,
            });
    
            // Reset form and set the new ticket number
            setName('');
            setEmail('');
            setPhoneNumber('');
            setTicketNumber(ticketNum);
            setProblem('');
            setErrorMessage('');
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
            console.log(error);
        }
    };
    
    return (
        <ContactSupportWrapper>
            <ContactForm onSubmit={handleSubmit}>
                <Input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <Input
                    type="tel"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                />
                <p>Ticket Number: {ticketNumber}</p>
                <TextArea
                    placeholder="Explain the problem"
                    value={problem}
                    onChange={(e) => setProblem(e.target.value)}
                    required
                />
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Button type="submit">{t("Submit")}</Button>
            </ContactForm>
        </ContactSupportWrapper>
    );
};

export default ContactSupport;