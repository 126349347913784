import styled from 'styled-components';

export const ErrorContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border: 1.5px solid red;
  border-radius: 4px;
  z-index: 998;
`;

export const ErrorText = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

export const RefillButton = styled.button`
  margin-top: 8px;
  padding: 8px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`; 
