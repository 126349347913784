import styled from "styled-components"
import { ArrowRightLong } from "@styled-icons/fa-solid/ArrowRightLong"
import { Home } from "@styled-icons/boxicons-regular/Home"
import { Person } from "@styled-icons/bootstrap/Person"
import { Payment } from "@styled-icons/fluentui-system-regular/Payment"
import { ReceiptCutoff } from "@styled-icons/bootstrap/ReceiptCutoff"
import { SupportAgent } from '@styled-icons/material/SupportAgent'
import { LogoutCircleR } from "@styled-icons/remix-line/LogoutCircleR"


export const MainWrapper = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center;  
    height: 100vh;
    width: 98.5vw;
    // background: red; 
    filter: ${props => props.blur};
    opacity: ${props => props.opacity}
    
    z-index: -1111;
    @media(max-width: 750px) {
        height: auto;
    }

`

export const DashVinWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 100%;
    max-width: 1680px;
`

export const VinInfoArea = styled.div`
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 100%;
    min-height: 120px;
    // min-width: 1500px;
    max-width: 1500px;
    max-height: 145px;

    @media(max-width: 750px) {
        display: flex;
    }

    
  
`

export const DashboardArea = styled.div`
    display: flex;
    height: 80%;
    width: 100%;
    // min-width: 1500px;
    min-height: 550px;
    max-height: 600px;
    
    @media(max-width: 750px) {
        flex-direction: column;
        min-height: none;
        max-height: none;

    }

`

export const VinNumberDetailsBox = styled.div`
    display: flex;
    height: 30%;
    width: 100%;


    @media(max-width: 750px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    

`

export const VinInputWithButtonBox = styled.div`
    display: flex;
    align-items: space-between;
    height: 70%;
    width: 100%;
    
    @media(max-width: 750px) {
        display: flex;
        align-items: center;
        min-height: 60px;

    }

`

export const VinInputBox = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 70%;
    

   
`;

export const VinInput = styled.input`
  display: flex;
  height: 40%;
  width: 90%;
  border: 1px solid lightgray;
  border-radius: 20px;
  background: #DDDBDB;
  margin-top: 1.5%;
  font-size: 1em;
  letter-spacing: 0.5em;
  font-weight: bold;
  opacity: 0.5;

  ::placeholder {
    text-align: center;
    content: "Search by VIN";
    letter-spacing: 0.2em;
    align-items: center;
    color: lightblack;
    font-size: 1.2em;
    font-weight: normal;
  }

  @media(max-width: 750px) {
    font-size: 0.7em;
    ::placeholder {
      letter-spacing: 0.2em;
      font-size: 0.7em;
      font-weight: normal;
    }
  }
`;




export const VinAmountBox = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 25%;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0.2em;

      @media(max-width: 750px) {
        font-size: 0.6em;
         letter-spacing: 0.1em;
        width: 30%;
    }

  
`
export const ArrowBox = styled.div`
    display: flex;
    height: 100%;
    width: 50%;
    justify-content: center;
    align-items: flex-end;

    @media(max-width: 750px) {
        width: 30%;
    }
`
export const RefillBox = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 25%;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0.2em;
    cursor: pointer;
       @media(max-width: 750px) {
        font-size: 0.6em;
        width: 30%;
        letter-spacing: 0.1em;
    }
`

export const LongArrow = styled.div`
    display: flex;
    width: 100%;
    height: 20px;

`

export const ButtonArea = styled.div`
      display: flex;
      height: 100%;
      width:30%;
      align-items: flex-start;
      justify-content: center;

      @medai(max-width: 750px) {
        display: flex;
        min-height: 55px;
      }

      
      

`
export const InputButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 50%;
    font-size: 1em;
    border: none;
    border-radius: 10px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #0069d9;
    }
    margin-top: 3%;
    max-width: 380px;
    max-height: 70px;

    @media(max-width: 750px) {
      height: 25px;
      font-size: 0.8em;
      width: 40%;
      min-width: 90px;
      border-radius: 5px;
    }

`;


export const VinResultArea = styled.div`
    display: flex;  
    flex-direction: column;
    height: auto;
    width: 70%;
    // background: lightblue;

    @media(max-width: 750px) {
        width: 100%;
        align-items: center;
        // justify-content: center;
        height: 80vh;
    }
    
`

export const VinResult = styled.div`
    display: flex;
    justify-content: center;
    height: 60%;
    width: 90%;
    border: 1.5px solid lightgray;
    border-radius: 20px;
    margin-top: 2.5%;
    overflow: scroll;
    // background: red;

    ::-webkit-scrollbar {
        display: none;
    }
    @media(max-width: 750px) {
    }


`

export const VinResultBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 10000%;
    // background: lightblue;
    


`

export const VinResultItem = styled.div`
    display: flex;
    height: 5vh;
    width: 100%;
    margin-top: 2%;
    border-bottom: 0.7px solid lightgray;
    
    
`;

export const VinNumber = styled.div`
    display: flex;
    height: 100%;
    width: 25%;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    cursor: pointer;

    @media(max-width: 750px) {
    font-size: 0.6em;
}

`
export const VinPurchaseDate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 25%;
    font-size: 1em;
    cursor: pointer;

    @media(max-width: 750px) {
        font-size: 0.6em;
    }
    
`
export const MakeForVin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 25%;
    font-size: 1em;
    cursor: pointer;

    @media(max-width: 750px) {
        font-size: 0.6em;
    }
`

export const YearForMake = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 25%;
    font-size: 1em;
    cursor: pointer;

    @media(max-width: 750px) {
        font-size: 0.6em;
    }
`

export const DashboardHeading = styled.div`
    display: flex;
    align-items: center;
    height: 10%;
    width: 90%;
    font-size: 2em;
    font-weight: bold;
    letter-spacing: 0.1em;
`


export const UserControlPanel = styled.div`
    display: flex;  
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 40%;
    // background: red;

    @media(max-width: 750px) {
        width: 95vw;
        height: 100%;
    }

`

export const IconWithTextArea = styled.div`
    display: flex;
    height: 10%;
    width: 90%;
    margin-top: 5%;


`

export const SvgBox = styled.div`
    display: flex;
    height: 100%;
    width: 25%;
    align-items: center;
    justify-content: center;
`

export const TitlesForControlPanel = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 75%;
    font-size: 1.1em;
    letter-spacing: 0.1em;
    color: lightgray;
    font-weight: bold;
    cursor: pointer;

`

export const HomeForDashboard = styled(Home)`
    display: flex;
    height: 100%;
    color: black;
    width: 70%;
    height: 70%;
`
export const PersonForDashboard = styled(Person)`
    display: flex;
    height: 100%;
    color: black;
    width: 70%;
    height: 70%;
`

export const PaymentForDashboard = styled(Payment)`
    display: flex;
    height: 100%;
    color: black;
    width: 70%;
    height: 70%;
`

export const OrderHistoryForDashboard = styled(ReceiptCutoff)`
    display: flex;
    height: 100%;
    color: black;
    width: 70%;
    height: 70%;
`

export const CallForDashboard = styled(SupportAgent)`
    display: flex;
    height: 100%;
    color: black;
    width: 70%;
    height: 70%;
`


export const SignOutForDashboard = styled(LogoutCircleR)`
    display: flex;
    height: 100%;
    color: black;
    width: 70%;
    height: 70%;
`
