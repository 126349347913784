import styled from "styled-components"
import {Link} from "react-router-dom";

export const MainWrapper = styled.div`
    display: flex; 
    flex-direction: column;   
    height: 100vh;
    width: 100vw;
    // background: ${props => props.back};
    filter: ${props => props.blur};
    opacity: ${props => props.opacity}

`

export const Body = styled.div`
    display: flex; 
    flex-direction: column;
    height: 70vh;
    width: 100vw;
    margin-top: 10vh;
    // background: lightblue;



`

export const PicHeading = styled.div`
    display: flex;  
    justify-content: center;
    align-items: center;
    height: 10%;
    width: 100%;
    color: lightgray;
    font-size: 2em;

    @keyframes colorChange {
        from {
            color: black;
            font-weight: bold;
        }
        to {
            color: lightgray;
        }
    }

    animation: colorChange 10s;

    @media(max-width: 750px) {
        font-size: 1.7em;
    }

   
`
export const PicArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 100%;
    // background: gray;


`

export const VehicleImg = styled.img`
    display: flex;
    height: 80%;
    width: 60%;
    max-height: 420px;
    max-width: 920px;

    @media(max-width: 750px) {
        width: 98%;
    }

`

export const ButtonSection = styled.div`
    display: flex;
    height: 10vh;
    

`

export const TextArea = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 50%;
    font-size: 2em;
    letter-spacing: 0.1em;
    font-weight: semi-bold;
    @media(max-width: 750px) {
        font-size: 1.5em;
    }

`

export const ButtonArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 50%;
    font-size: 2em;
    letter-spacing: 0.1em;

`

export const Button = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6em;
    height: 40%;
    width: 20%;
    background: none;
    border: 3px solid black;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    
    min-width: 150px;
    max-width: 170px;
    min-height: 50px;

    :hover {
        box-shadow: inset 0 0 0 150px #000000;
        -webkit-transition: ease-out 0.8s;
        -moz-transition: ease-out 0.8s;
         transition: ease-out 0.8s;
         color: white;

      }

      @media(max-width: 750px) {
        font-size: 0.5em;
        width: 10%;
    }


`