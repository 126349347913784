
import styled from 'styled-components';



export const FormContainer = styled.div`
  display: flex;
  height: 70vh;
  width: 30vw;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ccc;

  @media(max-width: 750px) {
    border: none;
    width: 98vw;
    height: 40vh;
  }
`;

export const Form = styled.form`
  display: flex; 
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;

  @media(max-width: 650px) {
      justify-content: space-evenly;;
      height: 100%;
  }

`

export const FormHeading = styled.h1`
  font-size: 24px;
//   margin-bottom: 16px;
`;

export const FormLabel = styled.label`
  font-size: 1.5em;
`;

export const FormInput = styled.input`
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;

  transition: border-color 0.5s ease;

  &:valid {
    border-color: green;
  }
`;

export const FormButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7%;
  width: 50%;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }

  max-width: 380px;
  max-height: 70px;

  @media(max-width: 750px) {
    height: 10%;
    width: 40%;
    min-width: 140px;
    min-height: 50px;
  }

`;



