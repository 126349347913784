import React, { useState, useEffect } from "react";
import {
  FormContainer,
  FormHeading,
  FormLabel,
  FormInput,
  FormButton,
  Form,
  SuccessMessage,
  ErrorMessage,
} from "../Styles/SignUpForm.Styles";
import { useTranslation } from "react-i18next";

function SignUpForm() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);

  const { t } = useTranslation();

  function handleSubmit(event) {
    event.preventDefault();

    // Save user information to localStorage
    localStorage.setItem("userInfo", JSON.stringify({
      fullName: `${name} ${lastName}`,
      address: '',
      contactPhone: '',
      email: email,
      password: password
    }));

    // Set success message and clear input fields
    setSuccessMessage(true);
    setName("");
    setLastName("");
    setEmail("");
    setBirthday("");
    setPassword("");
  }
  
  // Reset form after submission
  useEffect(() => {
    if (successMessage === true) {
      setName("");
      setLastName("");
      setEmail("");
      setBirthday("");
      setPassword("");
    }
  }, [successMessage]);

  // Return the form JSX
  return (
    <FormContainer>
      <FormHeading>Sign Up</FormHeading>
      <Form onSubmit={handleSubmit}>
        <FormInput
          placeholder="First Name"
          type="text"
          id="firstName"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
        />
        <FormInput
          placeholder="Last Name"
          type="text"
          id="lastName"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          required
        />
        <FormInput
          placeholder="Email"
          type="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <FormInput
          type="date"
          id="birthday"
          value={birthday}
          onChange={(event) => setBirthday(event.target.value)}
        />
        <FormInput
          placeholder="Password"
          type="password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />
        {successMessage ? <SuccessMessage>"{t("Success")}"</SuccessMessage> : null}
        <FormButton type="submit">{t("Sign Up")}</FormButton>
      </Form>
    </FormContainer>
  );
}

export default SignUpForm;
