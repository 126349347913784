import styled from 'styled-components'

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9977;
    // left: 1210px;
    height: 100vh;
    width: 20vw;
    background: white;
    max-width: 310px;
    border-left: 2px solid lightgray;
    -webkit-box-shadow: -3px 0px 11px -1px rgba(0,0,0,0.55); 
     box-shadow: -3px 0px 11px -1px rgba(0,0,0,0.55);

     @keyframes myAnim {
        0% {
            opacity: 0;
            transform: translateX(250px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    animation: myAnim 1s ease 0s 1 normal forwards;

    @media (max-width: 750px) {
        background: white;
        width: 70vw;
        z-index: 9933;
    }
`

export const Close = styled.div`
    display: flex;
    justify-content: flex-end;
    color: black;
    font-weight: bold;
    font-size: 2em;
    height: 5%;
    width: 90%;
    cursor: pointer;

`

export const LangBoxKa = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 10%;
    width: 80%;
    
    :hover {
        cursor: pointer;
        background: #f4f4f4;
        border-radius: 8px;
    }
`
export const LangBoxEs= styled(LangBoxKa)``
export const LangBoxUk= styled(LangBoxKa)``
export const LangBoxRu= styled(LangBoxKa)``
export const LangBoxEn= styled(LangBoxKa)`` 
export const LangBoxTr= styled(LangBoxKa)``
export const LangBoxZh= styled(LangBoxKa)``
export const LangBoxHi= styled(LangBoxKa)``

export const EngLang = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.3em;
    height: 50%;
    width: 90%;
    cursor: pointer; !important


`
export const ForeignLang = styled.div`
    display: flex;
    height: 50%;
    width: 90%;

`