import styled from 'styled-components'
import {Language} from "@styled-icons/material/Language"
import {Link} from "react-router-dom";

export const Header = styled.header`
    display: flex;
    height: 10%;
    min-height: 60px;
    max-height: 90px;
    width: 100%;

`
export const LogoArea = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    cursor: pointer;
`

export const LangSelecotrArea = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 50%;

    @media(max-width: 750px) {
        height: 100%;
    }

    
`

export const LogoText = styled(Link)`
    display: flex;
    align-items: center;
    height: 50%;
    width: 100%;
    font-size: 2em;
    letter-spacing: 0.2em;
    font-weight: bold;
    padding-left: 0.8em;
    text-decoration: none;
    color: black;

    @media(max-width: 750px) {
        font-size: 1.7em;
        letter-spacing: 0.15em;
    }
    

`
export const LogoSubText = styled.div`
    display: flex;
    // align-items: center;
    height: 50%;
    width: 100%;
    font-size: 1em;
    color: lightgray;
    padding-left: 0.8em;
    
    @media(max-width: 750px) {
        font-size: 0.7em;
    }

`
export const EarthIconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 10%;
    color: black;

    @media(max-width: 750px) {
        width: 20%;
        align-items: flex-start;
    }


`
export const EarthIcon = styled(Language)`
    display: flex;
    height: 50%;

    @media(max-width: 750px) {
        width: 70%;
    }

  
`

export const Lang = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 10%;
    font-weight: bold;
    font-size: 1.5em;
    color: black;
    letter-spacing: 0.1em;
    cursor: pointer;

    @media(max-width: 750px) {
        font-size: 1.2em;
        padding: 0.24em;
        align-items: flex-start;
        width: 24%; !important
    }



    :hover {
        background: #f4f4f4;
        border-radius: 5px;
    }

  


`
