import React, { useState, useEffect } from "react";
import {
  FormContainer,
  FormHeading,
  FormLabel,
  FormInput,
  FormButton,
  Form,
} from "../Styles/SignInForm.Styles";
import { useTranslation } from "react-i18next";

function SignInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();

  function handleSubmit(event) {
    event.preventDefault();
    const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (
      storedUserInfo &&
      storedUserInfo.email === email &&
      storedUserInfo.password === password
    ) {
      localStorage.setItem("isLoggedIn", "true");
      window.location.href = "/UserPanel";
    } else {
      alert("Invalid email or password");
    }
  }

  return (
    <FormContainer>
      <FormHeading>Sign In</FormHeading>
      <Form onSubmit={handleSubmit}>
        <FormInput
          placeholder="Email"
          type="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <FormInput
          placeholder="Password"
          type="password"
          id="pass"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />
        <FormButton type="submit">{t("Sign In")}</FormButton>
      </Form>
    </FormContainer>
  );
}

export default SignInForm;
