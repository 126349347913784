import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import {
  MainWrapper,
  OrderBody,
  FormWrapper,
  FormHeading,
  FirstNameText,
  Input,
  EmailAddress,
  VinNumber,
  VinInput,
  ButtonArea,
  Button,
  SucessMessageWrapper,
  ErrorMessage,
} from "../Styles/OrderPage.Styles";
import SignInForm from "../Components/SignInForm";
import SignUpForm from "../Components/SignUpForm";
import Hero from "../Components/Hero";
import UserPanel from './UserPanel'
import { useTranslation } from "react-i18next";

const OrderPage = () => {

  const [vin, setVin] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { t } = useTranslation();

  const vinSetter = (e) => {
    let userValue = e.target.value;
    setVin(userValue);
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (loggedIn === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const SendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zg927xb",
        "template_v9akl5k",
        e.target,
        "yUa38DGZ7vuChx03r"
      )
      .then(
        (result) => {
          // window.alert(`Success Status: ${result.Done}`);
          setSuccess("Success");
        },
        (error) => {
          console.log(error.text);
          setError("Error");
        }
      );
    e.target.reset();
  };

  return (
    <>
      {isLoggedIn ? (
        <UserPanel />
      ) : (
        <MainWrapper>
          <Hero />
          <OrderBody>
            <SignUpForm />
            <SignInForm />
            <FormWrapper onSubmit={SendEmail}>
              <FormHeading>{t("Continue As Guest")}</FormHeading>
              <FirstNameText>{t("Full Name")}</FirstNameText>
              <Input type="text" id="fname" name="fname" required />
              <EmailAddress>{t("Email Address")}</EmailAddress>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Purchased Report will be sent to the email"
                required
              />
              <EmailAddress>{t("Confirm Email Address")}</EmailAddress>
              <Input
                type="email"
                id="emailConfirm"
                name="emailConfirm"
                placeholder="Purchased Report will be sent to the email"
                required
              />
              <VinNumber>{t("Vin Number")}</VinNumber>
              <VinInput
                type="text"
                id="vin"
                name="vin"
                minLength={17}
                maxLength={17}
                style={{ textTransform: "uppercase" }}
                placeholder="Enter only Letters and numbers 17 char long"
                onChange={vinSetter}
                value={vin}
                required
              />
              <ButtonArea>
                {success === "Success" ? (
                  <SucessMessageWrapper>
                    {t("Success Please Check Your Email")}
                  </SucessMessageWrapper>
                ) : error === "Error" ? (
                  <ErrorMessage>{t("Somthing Went Really Wrong")}</ErrorMessage>
                ) : null}
                <Button>{t("Continue to Payment")}</Button>
              </ButtonArea>
            </FormWrapper>
          </OrderBody>
        </MainWrapper>
      )}
    </>
  );
};

export default OrderPage;
