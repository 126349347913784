import React, { useState, useEffect } from 'react';
import {
    ProfileSettingsWrapper,
    ProfileNameAddressPhoneBox,
    ProfileEmailPassword,
    DeletAccountBox,
    FullNameBox,
    LabelForFullName,
    ActualFullName,
    EditActualFullName,
    AddressBox,
    LabelForAddress,
    ActualAddress,
    EditActualAddress,
    ContactPhoneBox,
    LabelForPhone,
    ActualPhoneNumber,
    EditActualPhoneNumber,
    EmailBox,
    EmailLabel,
    ActualEmail,
    EditActualEmail,
    PasswordBox,
    LabelForPass,
    ActualPass,
    EditActualPass,
    DeleteAcountBox,
    ActualDelete,
    InputForFullName,
    InputForAddress,
    InputForPhoneEdit,
    InputForEmailEdit,
    InputForPasswordEdit,
} from '../Styles/ProfileSettings.Styles';
import { useTranslation } from 'react-i18next';

const ProfileSettings = () => {
    const [userInfo, setUserInfo] = useState(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            return JSON.parse(storedUserInfo);
        } else {
            return {
                fullName: '',
                address: '',
                contactPhone: '',
                email: '',
                password: ''
            };
        }
    });

    const [editFullName, setEditFullName] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [editPhone, setEditPhone] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [editPassword, setEditPassword] = useState(false);

    const [fullNameError, setFullNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const { t } = useTranslation();

    const toggleFullNameEdit = () => {
        setEditFullName(!editFullName);
    };

    const toggleAddressEdit = () => {
        setEditAddress(!editAddress);
    };

    const togglePhoneEdit = () => {
        setEditPhone(!editPhone);
    };

    const toggleEmailEdit = () => {
        setEditEmail(!editEmail);
    };

    const togglePasswordEdit = () => {
        setEditPassword(!editPassword);
    };

    const handleFullNameChange = (e) => {
        setUserInfo({ ...userInfo, fullName: e.target.value });
    };

    const handleAddressChange = (e) => {
        setUserInfo({ ...userInfo, address: e.target.value });
    };

    const handlePhoneChange = (e) => {
        setUserInfo({ ...userInfo, contactPhone: e.target.value });
    };

    const handleEmailChange = (e) => {
        setUserInfo({ ...userInfo, email: e.target.value });
        handleSaveChanges();
    };

    const handlePasswordChange = (e) => {
        setUserInfo({ ...userInfo, password: e.target.value });
        handleSaveChanges();
    };

    const saveUserInfo = () => {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
    };

    const clearUserInfo = () => {
        localStorage.removeItem('userInfo');
        setUserInfo({});
    };

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (userInfo.email && !emailRegex.test(userInfo.email)) {
            setEmailError('Invalid email address');
            return false;
        }
        setEmailError('');
        return true;
    };

    const validatePassword = () => {
        if (userInfo.password && userInfo.password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
            return false;
        }
        setPasswordError('');
        return true;
    };

    const handleSaveChanges = () => {
        let isEmailValid = validateEmail();
        let isPasswordValid = validatePassword();

        if (isEmailValid && isPasswordValid) {
            saveUserInfo();
        }
    };

    useEffect(() => {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
      }, [userInfo]);
      

    return (
        <ProfileSettingsWrapper>
            <ProfileNameAddressPhoneBox>
                <FullNameBox>
                    <LabelForFullName>{t("Full Name")}</LabelForFullName>
                    {editFullName ?
                        <InputForFullName
                            type="text"
                            value={userInfo.fullName}
                            onChange={handleFullNameChange}
                            onBlur={toggleFullNameEdit}
                            autoFocus /> : <ActualFullName>{userInfo.fullName}</ActualFullName>}
                    <EditActualFullName
                        onClick={toggleFullNameEdit}
                    >{editFullName ? "Cancel" : "Edit"}
                    </EditActualFullName>
                </FullNameBox>
                <AddressBox>
                    <LabelForAddress>{("Address")}</LabelForAddress>
                    {editAddress ? <InputForAddress
                        type="text"
                        value={userInfo.address}
                        onChange={handleAddressChange}
                        onBlur={toggleAddressEdit}
                        autoFocus
                    /> : <ActualAddress>{userInfo.address}</ActualAddress>}
                    <EditActualAddress
                        onClick={toggleAddressEdit}
                    >{editAddress ? "Cancel" : "Edit"}
                    </EditActualAddress>
                </AddressBox>
                <ContactPhoneBox>
                    <LabelForPhone>{t("Contact Phone")}</LabelForPhone>
                    {editPhone ? <InputForPhoneEdit
                        type="text"
                        value={userInfo.contactPhone}
                        onChange={handlePhoneChange}
                        onBlur={togglePhoneEdit}
                        autoFocus
                    /> :
                        <ActualPhoneNumber>{userInfo.contactPhone}</ActualPhoneNumber>}
                    <EditActualPhoneNumber
                        onClick={togglePhoneEdit}
                    >{editPhone ? "Cancel" : "Edit"}
                    </EditActualPhoneNumber>
                </ContactPhoneBox>
            </ProfileNameAddressPhoneBox>
            <ProfileEmailPassword>
                <EmailBox>
                    <EmailLabel>{t("Email")}</EmailLabel>
                    {editEmail ? <InputForEmailEdit
                        type="email"
                        value={userInfo.email}
                        onChange={handleEmailChange}
                        onBlur={toggleEmailEdit}
                        autoFocus
                    /> :
                        <ActualEmail>{userInfo.email}</ActualEmail>}
                    <EditActualEmail
                        onClick={toggleEmailEdit}
                    >{editEmail ? "Cancel" : "Edit"}
                    </EditActualEmail>
                </EmailBox>
                <PasswordBox>
                    <LabelForPass>{t("Password")}</LabelForPass>
                    {editPassword ? <InputForPasswordEdit
                        type="password"
                        value={userInfo.password}
                        onChange={handlePasswordChange}
                        onBlur={togglePasswordEdit}
                        autoFocus
                    /> :
                        <ActualPass>{userInfo.password}</ActualPass>}
                    <EditActualPass
                        onClick={togglePasswordEdit}
                    >{editPassword ? "Cancel" : "Edit"}
                    </EditActualPass>
                </PasswordBox>
            </ProfileEmailPassword>
            <DeletAccountBox>
                <ActualDelete
                    onClick={() => {
                        localStorage.removeItem('userInfo');
                        setUserInfo({});
                    }}>
                    {t("Delete Account")}
                </ActualDelete>
            </DeletAccountBox>
        </ProfileSettingsWrapper>
    );
};

export default ProfileSettings;
