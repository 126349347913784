import React, { useState, createContext, useContext } from 'react'


export const LangContext = createContext([{}, () => { }])
export const UserPanelCompCounter = createContext([{}, () => { }])
export const AddCardCounter = createContext([{}, () => { }])
export const TokensContext = createContext({ tokens: 0, setTokens: () => { } })
export const BlurMainWrapperOnMobileClick = createContext([{}, () => { }])
export const MobileMenuContext = createContext([{}, () => { }])
export const SelectedLang = createContext([{}, () => { }])
export const ActualVinNumber = createContext([{}, () => { }])
export const VinDecodeFunc = createContext([{}, () => { }])
export const StripeBooleanForPurchase = createContext([{}, () => { }])
export const PurchasedCoins = createContext([{}, () => { }])
export const VinAmountError = createContext([{}, () => { }])

const LangProvider = (props) => {

    const [lang, setLang] = useState(0)
    const [openPanel, setOpenPanel] = useState(0)
    const [openAddCard, setOpenAddCard] = useState(false)
    const [tokens, setTokens] = useState(0)
    const [blurMainWrapper, setBlurMainWrapper] = useState(false)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState("en")
    const [actualVinNumber, setActualVinNumber] = useState("")
    const [decodeVinContext, setDecodeVinContext] = useState(false)
    const [stripeBooleanForPurchase, setStripeBooleanForPurchase] = useState(false)
    const [purchasedCoins, setPurchasedCoins] = useState("");
    const [showVinAmountError, setShowVinAmountError] = useState(false);


    return (
        <LangContext.Provider value={{ lang, setLang }}>
            <UserPanelCompCounter.Provider value={{ openPanel, setOpenPanel }}>
                <AddCardCounter.Provider value={{ openAddCard, setOpenAddCard }}>
                    <TokensContext.Provider value={{ tokens, setTokens }}>
                        <BlurMainWrapperOnMobileClick.Provider value={{ blurMainWrapper, setBlurMainWrapper }}>
                            <MobileMenuContext.Provider value={{ isMobileMenuOpen, setIsMobileMenuOpen }}>
                                <SelectedLang.Provider value={{ selectedLang, setSelectedLang }}>
                                    <ActualVinNumber.Provider value={{ actualVinNumber, setActualVinNumber }}>
                                        <VinDecodeFunc.Provider value={{ decodeVinContext, setDecodeVinContext }}>
                                            <StripeBooleanForPurchase.Provider value={{ stripeBooleanForPurchase, setStripeBooleanForPurchase }}>
                                                <PurchasedCoins.Provider value={{ purchasedCoins, setPurchasedCoins }}>
                                                    <VinAmountError.Provider value={{ showVinAmountError, setShowVinAmountError }}>
                                                    {props.children}
                                                    </VinAmountError.Provider>
                                                </PurchasedCoins.Provider>
                                            </StripeBooleanForPurchase.Provider>
                                        </VinDecodeFunc.Provider>
                                    </ActualVinNumber.Provider>
                                </SelectedLang.Provider>
                            </MobileMenuContext.Provider>
                        </BlurMainWrapperOnMobileClick.Provider>
                    </TokensContext.Provider>
                </AddCardCounter.Provider>
            </UserPanelCompCounter.Provider>
        </LangContext.Provider >)
}

export default LangProvider

