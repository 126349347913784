import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  OrderHistoryWrapper,
  ContainerForToken,
  InputForToken,
  ButtonForToken,
} from "../Styles/AddTokens.Styles";
import { loadStripe } from "@stripe/stripe-js";
import { TokensContext, StripeBooleanForPurchase, PurchasedCoins, UserPanelCompCounter} from "../Context/context";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(
  "pk_test_51L2aEUIf9B40FRjJNuzlNS7wvkXgeT7gT86QWGmclQOdqyVdHsz1263BxZVJyT0ZHX7ubfO9bBKBudCRSIbdUo0200zHwLWRu6" // Replace with your own public key
);

const AddTokens = () => {
  const { tokens, setTokens } = useContext(TokensContext);
  const { stripeBooleanForPurchase, setStripeBooleanForPurchase } = useContext(StripeBooleanForPurchase);
  const {purchasedCoins, setPurchasedCoins} = useContext(PurchasedCoins);
  const {openPanel, setOpenPanel} = useContext(UserPanelCompCounter);
  const { t } = useTranslation();
  const [localButtonForVerification, setLocalButtonForVerification] = useState(false);

  const handleTokenChange = (e) => {
    setPurchasedCoins(e.target.value);
  };

  useEffect(() => {
    const tokenQuantity = parseInt(localStorage.getItem("tokenQuantity"));
    if (!isNaN(tokenQuantity)) {
      setTokens(tokenQuantity);
    }
  }, []);

  const handleBuyClick = useCallback(async () => {
    const stripe = await stripePromise;
    const quantity = parseInt(purchasedCoins) || 0;

    if (quantity > 0) {
      const { error, paymentIntent } = await stripe.redirectToCheckout({
        lineItems: [{ price: "price_1Mf8GTIf9B40FRjJgEAqvTEX", quantity }],
        mode: "payment",
        successUrl: `${window.location.origin}/UserPanel`,
        cancelUrl: `${window.location.origin}/UserPanel`,
      });
      if (!error) {
        console.log("success: Updating token quantity");
        const currentQuantity =
          parseInt(localStorage.getItem("tokenQuantity")) || 0;
        const newQuantity = currentQuantity + quantity;
        localStorage.setItem("tokenQuantity", newQuantity);
        setTokens(newQuantity);
        setPurchasedCoins(""); // clear input

        // Update user tokens if payment is successful
        const response = await fetch("/charge", {
          method: "POST",
          body: JSON.stringify({
            token: paymentIntent.id,
            amount: quantity * 1000, // Amount in cents
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.status === 200) {
          console.log("Tokens updated successfully!");
        } else {
          console.error("Failed to update tokens.");
        }
      }
    } else {
      console.error("Invalid quantity value");
    }
    }, [purchasedCoins]); 

  
  
  const handleStripePayment = () => {
     setStripeBooleanForPurchase(true);
     setLocalButtonForVerification(true);
    }

    useEffect(() => {
      if (stripeBooleanForPurchase) {
        handleBuyClick();
        setStripeBooleanForPurchase(false);
      }
    }, [stripeBooleanForPurchase, handleBuyClick]);
    
    console.log(stripeBooleanForPurchase, 'stripeBooleanForPurchase')

    console.log(purchasedCoins, 'purchasedCoins')

  return (
    <OrderHistoryWrapper>
      <ContainerForToken>
        <InputForToken
          type="number"
          min="1"
          placeholder="Enter number of tokens to buy"
          value={purchasedCoins}
          onChange={handleTokenChange}
        />
        <ButtonForToken onClick={handleStripePayment}>{t("Buy Tokens")}</ButtonForToken>
      </ContainerForToken>
    </OrderHistoryWrapper>
  );
};

export default AddTokens;
