import styled from "styled-components"

export const MainWrapper = styled.div`
    display: flex; 
    flex-direction: column;   
    height: 100vh;
    width: 100vw;
    // background: red; 

    @media(max-width: 750px) {
        height: auto;
    }

  
`

export const OrderBody = styled.div`
    display: flex;
    margin-top: 10vh;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100vw;

    @media(max-width: 750px) {
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: space-between;
    }

`

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 30%;
    min-height: 540px;
    // min-width: 605px;
    max-width: 605px;

    @media(max-width: 750px) {
        min-width: 300px;
        width: 95%;
        // height: 100vh;

    }

`

export const FormHeading = styled.div`
    display: flex;
    align-items: flex-end;
    height: 5%;
    width: 70%;
    color: black;
    font-size: 1.5em;
    font-weight: bold;

`

export const FirstNameText = styled.div`
    display: flex;
    align-items: flex-end;
    height: 5%;
    width: 70%;
    color: black;
    font-size: 1em;
    max-width: 300px;


`

export const Input = styled.input`
    display: flex;
    // height: 7%;
    width: 70%;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;

    // border: none;

    ::placeholder {
        font-size: 0.7em;

        @media(max-width: 750px){
            font-size: 0.5em;
        }
    }

    max-height: 50px;
    max-width: 300px;

`
export const EmailAddress = styled.div`
    display: flex;
    align-items: flex-end;
    height: 5%;
    width: 70%;
    color: black;
    font-size: 1em;
    max-width: 300px;


`

export const VinNumber = styled.div`
    display: flex;
    align-items: flex-end;
    height: 5%;
    width: 70%;
    color: black;
    font-size: 1em;
    max-width: 300px;


`

export const VinInput = styled.input`
    display: flex;
    justify-content: center;
    // height: 7%;
    width: 70%;
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 0.3em;
    max-height: 50px;
    max-width: 300px;
    text-align: center:

    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;


    ::placeholder {
        font-size: 0.7em;
        letter-spacing: normal;
        font-weight: normal;

        @media(max-width: 750px){
            font-size: 0.5em;
        }
    }


`

export const ButtonArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20%;
    width: 100%;

`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    width: 40%;
    color: white;
    background-color: #007bff;
    border-radius: 10px;
    border: none;
    font-size: 1em;
    cursor: pointer;

    max-width: 380px;
    max-height: 70px;

    @media(max-width: 750px) {
        width: 50%;
        height: 25%;
         min-width: 140px;
        min-height: 50px;
    }

    &:hover {
        background-color: #0069d9;
      }
    
`

export const SucessMessageWrapper = styled.div`
    display: flex;
    justify-content: center;
    height 20%;
    width: 25%;
    color: green;
    font-weight: bold;
    font-size: 1em;


    animation: SuccessAnimation 1s ease 0s 1 normal forwards;

    @keyframes SuccessAnimation {
        0% {
            transform: scale(0);
        }
    
        100% {
            transform: scale(1);
        }
    }
    
    @media(max-width: 750px) {
        width: 80%;
    }
`

export const ErrorMessage = styled.div`
    display: flex;
    justify-content: center;
    height 20%;
    width: 25%;
    color: red;
    font-weight: bold;
    font-size: 1em;


    animation: ErrorAnimation 3s ease 0s 1 normal forwards;

    @keyframes ErrorAnimation {
        0%,
        100% {
            transform: translateX(0);
        }
    
        10%,
        30%,
        50%,
        70% {
            transform: translateX(-10px);
        }
    
        20%,
        40%,
        60% {
            transform: translateX(10px);
        }
    
        80% {
            transform: translateX(8px);
        }
    
        90% {
            transform: translateX(-8px);
        }
    }

`