import styled from 'styled-components'


export const ContactSupportWrapper = styled.div`
    display: flex;
    // justify-content: center;
    height: 100%;
    width: 90%;
    margin-top: 2.5%;

    @media(max-width: 750px) {
        display: flex;
        margin-left: 5%;
        height: 80%;
    }

`

export const ContactForm = styled.form`
  display: flex;
  height: 100%;
  width: 50%;
  flex-direction: column;
  align-items: center;

  @media(max-width: 750px) {
    display: flex;
    width: 90%;
  }
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  -webkit-appearance: none;
`;

export const TextArea = styled.textarea`
display: flex;
width: 100%;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  -webkit-appearance: none;
  resize: none;
  height: 200px;
`;

export const Button = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;
