import React, { useContext } from "react";
import {
  MainWrapper,
  Body,
  PicHeading,
  PicArea,
  VehicleImg,
  ButtonSection,
  TextArea,
  ButtonArea,
  Button,
} from "../Styles/Home.Styles";
import carPic from "../Assets/out.png";
import Hero from "../Components/Hero";
import Typewriter from "typewriter-effect";
import LangProvider, { LangContext , SelectedLang} from "../Context/context";
import { useTranslation } from 'react-i18next'



const Home = () => {
  const { lang, setLang } = useContext(LangContext);
  const { selectedLang, setSelectedLang } = useContext(SelectedLang);
  const { t } = useTranslation(); 
  return (
    <MainWrapper>
      <Hero />
      <Body style={{ filter: lang === 1 ? "blur(9px)" : "blur()" }}>
        <PicHeading>
          <Typewriter
            onInit={(typewriter, speed) => {
              typewriter
                .typeString(t("Main"))
                .pauseFor(1000)
                .start();
            }}
            options={{
              delay: 100,
            }}
          />
        </PicHeading>
        <PicArea>
          <VehicleImg src={carPic} />
        </PicArea>
        <ButtonSection>
          <TextArea>{t("Reval it:")}</TextArea>
          <ButtonArea>
            <Button to="/orderpage">{t("Order Now")}</Button>
          </ButtonArea>
        </ButtonSection>
      </Body>
    </MainWrapper>
  );
};

export default Home;
