import React, { useState, useContext } from "react";
import {
  MobileViewUserControlContainer,
  DashIconForMoblieView,
  TitleForMoblieView,
  ArrowForMoblieView,
  MobileHomeIcon,
  ArrowDown,
  CloseMobileMenu
} from "../Styles/MobileViewUserControl.Styles";
import {
  UserPanelCompCounter,
  BlurMainWrapperOnMobileClick,
  MobileMenuContext,
} from "../Context/context";
import { useTranslation } from "react-i18next";

const MobileViewUserControl = () => {
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useContext(MobileMenuContext);
  const { openPanel, setOpenPanel } = useContext(UserPanelCompCounter);
  const { blureMainWrapper, setBlurMainWrapper } = useContext(
    BlurMainWrapperOnMobileClick
  );

  const { t } = useTranslation();

  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setBlurMainWrapper(true);
  };

  //handle dashboard click
  const handleDashboard = () => {
    setOpenPanel(0);
    setIsMobileMenuOpen(false);
    setBlurMainWrapper(false);
  };

  return (
    <MobileViewUserControlContainer>
      <DashIconForMoblieView>
        <MobileHomeIcon />
      </DashIconForMoblieView>
      <TitleForMoblieView onClick={handleDashboard}>{t("Dashboard")}</TitleForMoblieView>
      <ArrowForMoblieView onClick={handleMobileMenu}>
        <ArrowDown />
      </ArrowForMoblieView>
    </MobileViewUserControlContainer>
  );
};

export default MobileViewUserControl;
