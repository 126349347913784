import './App.css';
import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import OrderPage from './Pages/OrderPage';
import UserPanel from './Pages/UserPanel';
import LangProvider from './Context/context';

function App() {
  return (
    <Suspense fallback={<div>Loading translations...</div>}>
      <LangProvider>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/orderpage" element={<OrderPage />} />
          <Route exact path="/userpanel" element={<UserPanel />} />
        </Routes>
      </LangProvider>
    </Suspense>
  );
}

export default App;
