import styled from 'styled-components'


export const OrderHistoryWrapper = styled.div`
    display: flex;
    height: 70%;
    width: 90%;
    margin-top: 2.5%;

`

export const ContainerForToken = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media(max-width: 750px) {
    display: flex;
    width: 90%;
  }
`;

export const InputForToken = styled.input`
  margin-bottom: 10px;

  @media(max-width: 750px) {
    display: flex;
    height: 40px;
    width: 80%;
  }
`;

export const ButtonForToken = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10%;
  width: 80%;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }

  max-width: 380px;
  max-height: 70px;

  @media(max-width: 750px) {
    height: 10%;
    width: 40%;
    min-width: 140px;
    min-height: 50px;
  }

`;