import styled from 'styled-components'


export const PaymentMethodWrapper = styled.div`
    display: flex;
    height: 70%;
    width: 90%;
    margin-top: 2.5%;

    @media(max-width: 750px) {
      display: flex;
      align-items: center;
    }
   

`

export const AddCardWithTextBox = styled.div`
    display: flex;
    flex-direction: column;
    heght: 100%;
    width: 40%;
    border: 1px solid lightgray;
    border-radius: 20px;
    max-width: 320px;

    @media(max-width: 750px) {
      display: flex;
      height: 90%;
      width: 95%;
    }



    

`
export const CardImgBg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
    background: #F4F4F4;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`
export const TextWithButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`

export const CardImg = styled.img`
    display: flex;
    // height: 100%;
    width: 100%;
`

export const TextForAddCard = styled.div`
    display: flex;
    align-items: center;
    height: 50%;
    width: 90%;
    font-size: 1.1em;
    color: #888080;
    
    
`

export const AddCardButtonBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 90%;

`
export const AddCardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 50%;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }

  max-width: 380px;
  max-height: 70px;

  @media(max-width: 750px) {
    height: 10%;
    width: 40%;
    min-width: 140px;
    min-height: 50px;
  }

`;


export const DeleteCardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 50%;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  background-color: #ff6b6b;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }

  max-width: 380px;
  max-height: 70px;

  @media(max-width: 750px) {
    height: 10%;
    width: 40%;
    min-width: 140px;
    min-height: 50px;
  }

`;

