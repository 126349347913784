import React, { useState, useContext, useEffect } from "react";
import {
  Label,
  Input,
  Button,
  Overlay,
  PopupContainer,
  //   ErrorText,
  List,
  ListItem,
  Container
} from "../Styles/DecodeVin.Styles";
import { ActualVinNumber, VinDecodeFunc } from "../Context/context";

const DecodeVin = () => {
  const { actualVinNumber, setActualVinNumber } = useContext(ActualVinNumber);
  const { decodeVinContext, setDecodeVinContext } = useContext(VinDecodeFunc);
  const [vinData, setVinData] = useState(null);
  const [error, setError] = useState(null);

  const handleDecodeClick = async () => {
    try {
      const response = await fetch(
        `https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${actualVinNumber}?format=json`
      );
      const data = await response.json();

      const importantData = {};
      const importantVariables = [
        "Make",
        "Model",
        "Model Year",
        "Engine Configuration",
        "Transmission Style",
        "Drive Type",
        "Body Type",
        "Trim",
      ];

      data.Results.forEach((result) => {
        if (importantVariables.includes(result.Variable)) {
          importantData[result.Variable] = result.Value;
        }
      });

      setVinData(importantData);
      setError(null);
    } catch (error) {
      setVinData(null);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (decodeVinContext) {
      handleDecodeClick();
      setDecodeVinContext(false); // Reset the decodeVinFunction state after calling the function
    }
  }, [decodeVinContext]);

  return (
    <>
      {error && (
        <Overlay>
          <PopupContainer>
            {/* <ErrorText>{error}</ErrorText> */}
          </PopupContainer>
        </Overlay>
      )}
      {vinData && (
        <Overlay>
          <PopupContainer>
            <h2>Decoded VIN:</h2>
            <List>
              {Object.entries(vinData).map(([variable, value]) => (
                <ListItem key={variable}>
                  <strong>{variable}</strong>: {value}
                </ListItem>
              ))}
            </List>
            <Button>Buy Vin</Button>
          </PopupContainer>
        </Overlay>
      )}
    </>
  );
};

export default DecodeVin;
