
import React, { useContext, useState } from "react";
import {
  AddCardWrapper,
  CloseAddVCardBox,
  CloseAddVCardX,
  StyledContainer,
  StyledForm,
  StyledInput,
  StyledButton,
} from "../Styles/AddCard.Styles";
import { AddCardCounter } from "../Context/context";
import { useTranslation } from "react-i18next";

const AddCard = () => {
  const { openAddCard, setOpenAddCard } = useContext(AddCardCounter);
  const { t } = useTranslation();

  const [cardData, setCardData] = useState({
    name: "",
    number: "",
    cvv: "",
    billingZipCode: "",
    expirationMonth: "",
    expirationYear: "",
  });
  const [showCardDetails, setShowCardDetails] = useState(false);
  const closeHandler = () => {
    setOpenAddCard(false);
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setCardData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddCard = () => {
    localStorage.setItem("cardData", JSON.stringify(cardData));
    setShowCardDetails(true);
    closeHandler();
  };


  return (
    <AddCardWrapper>
      <CloseAddVCardBox onClick={closeHandler}>
        <CloseAddVCardX>X</CloseAddVCardX>
      </CloseAddVCardBox>
      <StyledContainer>
        <StyledForm>
          <StyledInput type="text" placeholder="Name on Card" name="name" onChange={handleInput} />
          <StyledInput type="text" placeholder="Card Number" name="number" inputMode="numeric" pattern="[0-9]*" maxLength={16} onChange={handleInput} />
          <StyledInput type="text" placeholder="CVV" name="cvv" onChange={handleInput} />
          <StyledInput type="text" placeholder="Billing Zip Code" name="billingZipCode" onChange={handleInput} />
          <StyledInput type="text" placeholder="Expiration Month (Exp: 03)" name="expirationMonth" onChange={handleInput} />
          <StyledInput type="text" placeholder="Expiration Year (Exp: 2033)" name="expirationYear" onChange={handleInput} />
        </StyledForm>
        <StyledButton onClick={handleAddCard}>{t("Add Card")}</StyledButton>
      </StyledContainer>
    </AddCardWrapper>
  );
};

export default AddCard;