


const vinResult = [
    {
        vin: "1HD1MAM27DB851523",
        year:"2021",
        make: "Audi A7",
        date: "2023-01-02 22:01:23",
    },
    {
        vin: "5T4KP41Y923355338",
        year:"2022",
        make: "Jaguar E-Pace",
        date: "2023-10-02 05:01:23",
    },
    {
        vin: "YS3ED48E5Y3070016",
        year:"2020",
        make: "GMC Acadia",
        date: "2023-02-02 22:01:23",
    },
    {
        vin: "3LNHL2JC5CR800713",
        year:"2021",
        make: "Chrysler 300",
        date: "2023-02-02 22:01:23",
    },

    {
        vin: "1FUJA6AS83LK36671",
        year:"2018",
        make: "GMC Sierra",
        date: "2023-02-10 14:01:23",
    },
    {
        vin: "JH4KA8260RC000063",
        year:"2016",
        make: "Infiniti QX60",
        date: "2023-02-02 22:01:23",
    },
    {
        vin: "JH4KA3240KC018604",
        year:"2021",
        make: "Bmw 550i",
        date: "2023-02-02 22:01:23",
    },
    {
        vin: "WDCGG8HB0AF462890",
        year:"2023",
        make: "Mercedes-Benz S580",
        date: "2023-02-02 22:01:23",
    },
]

export default vinResult