import { useState, useContext } from "react";
import LangProvider, { LangContext } from "../Context/context";
import {
  Header,
  LogoArea,
  LangSelecotrArea,
  LogoText,
  LogoSubText,
  EarthIcon,
  Lang,
  EarthIconBox,
} from "../Styles/Hero.styles";
import LangSlider from "./LangSlider";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { lang, setLang } = useContext(LangContext);
  const { t } = useTranslation();
  const openLang = () => {
    setLang(lang + 1);
  };

  return (
    // <LangProvider>
    <Header>
      <LogoArea>
        <LogoText to="/">VinDigits</LogoText>
        <LogoSubText>{t("Affordable Vehicle History Report")}</LogoSubText>
      </LogoArea>
      <LangSelecotrArea>
        <EarthIconBox>
          <EarthIcon />
        </EarthIconBox>
        <Lang onClick={openLang}>Eng</Lang>
      </LangSelecotrArea>
      {lang === 0 ? null : <LangSlider />}
    </Header>
    // </LangProvider>
  );
};

export default Hero;
