import React, { useContext, useState, useEffect } from "react";
import {
  MainWrapper,
  DashVinWrapper,
  VinInfoArea,
  DashboardArea,
  VinNumberDetailsBox,
  VinAmountBox,
  ArrowBox,
  RefillBox,
  VinInputWithButtonBox,
  VinInputBox,
  VinInput,
  InputButton,
  ButtonArea,
  VinResultArea,
  UserControlPanel,
  DashboardHeading,
  VinResult,
  IconWithTextArea,
  SvgBox,
  TitlesForControlPanel,
  HomeForDashboard,
  PersonForDashboard,
  PaymentForDashboard,
  OrderHistoryForDashboard,
  CallForDashboard,
  SignOutForDashboard,
  VinResultBox,
  VinResultItem,
  VinNumber,
  YearForMake,
  MakeForVin,
  VinPurchaseDate,
} from "../Styles/UserPanel.Styles";
import Hero from "../Components/Hero";
import {
  LangContext,
  UserPanelCompCounter,
  AddCardCounter,
  TokensContext,
  BlurMainWrapperOnMobileClick,
  MobileMenuContext,
  ActualVinNumber,
  VinDecodeFunc,
  StripeBooleanForPurchase,
  PurchasedCoins,
  VinAmountError,
} from "../Context/context";
import ProfileSettings from "../Components/ProfileSettings";
import PaymentMethod from "../Components/PaymentMethod";
import AddTokens from "../Components/AddTokens";
import ContactSupport from "../Components/ContactSupport";
import vinResult from "../Data/VinResult.js";
import AddCard from "../Components/AddCard";
import MobileViewUserControl from "../Components/MobileViewUserControl";
import {
  WrapperForMobileMenu,
  CloseMobileMenu,
} from "../Styles/MobileViewUserControl.Styles";
import ErrorForTokensAmunt from "../Components/ErrorForTokensAmount";
import DecodeVin from "../Components/DecodeVin";

const UserPanel = () => {
  const { lang, setLang } = useContext(LangContext);
  const { openPanel, setOpenPanel } = useContext(UserPanelCompCounter);
  const { openAddCard, setOpenAddCard } = useContext(AddCardCounter);
  const { tokens, setTokens } = useContext(TokensContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { actualVinNumber, setActualVinNumber } = useContext(ActualVinNumber);
  const { blurMainWrapper, setBlurMainWrapper } = useContext(
    BlurMainWrapperOnMobileClick
  );
  const { isMobileMenuOpen, setIsMobileMenuOpen } =
    useContext(MobileMenuContext);
  const {showVinAmountError, setShowVinAmountError} = useContext(VinAmountError);
  const [showVinDecoder, setShowVinDecoder] = useState(false);
  const { decodeVinContext, setDecodeVinContext } = useContext(VinDecodeFunc);
  const { stripeBooleanForPurchase, setStripeBooleanForPurchase } = useContext(
    StripeBooleanForPurchase
  );
  const { purchasedCoins, setPurchasedCoins } = useContext(PurchasedCoins);

  // Get the window size
  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const resizeHandler = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", resizeHandler);
      return () => {
        window.removeEventListener("resize", resizeHandler);
      };
    }, []);
    return size;
  };
  const [height, width] = useWindowSize();

  //checks if the user is logged in or not
  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (loggedIn === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const handleDashboard = () => {
    setOpenPanel(0);
    setIsMobileMenuOpen(false);
    setBlurMainWrapper(false);
  };

  const handleProfileSetting = () => {
    setOpenPanel(1);
    setIsMobileMenuOpen(false);
    setBlurMainWrapper(false);
  };

  const handlePayment = () => {
    setOpenPanel(2);
    setIsMobileMenuOpen(false);
    setBlurMainWrapper(false);
  };

  const handleOrderHistory = () => {
    setOpenPanel(3);
    setIsMobileMenuOpen(false);
    setBlurMainWrapper(false);
  };

  const handleContactSupport = () => {
    setOpenPanel(4);
    setIsMobileMenuOpen(false);
    setBlurMainWrapper(false);
  };

  const handleMobleMenuClose = () => {
    setIsMobileMenuOpen(false);
    setBlurMainWrapper(false);
  };

  const handleSignOut = () => {
    // Remove the user data from local storage
    localStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
    window.location.href = "/";
  };

  const handleVinChange = (e) => {
    setActualVinNumber(e.target.value);
  };

  const handleVinSubmit = (e) => {
    if (tokens === 0 || actualVinNumber === "") {
      setShowVinAmountError(true);
    } else {
      setShowVinAmountError(false);
      setShowVinDecoder(true);
    }
  };

  const handleDecodeVin = () => {
    setDecodeVinContext(true);
  };

  const handleStripePayment = () => {
    setOpenPanel(3);
  };

  console.log(stripeBooleanForPurchase, "stripeBooleanForPurchase");
  console.log(purchasedCoins, "purchasedCoins");

  return (
    <>
      {showVinDecoder ? <DecodeVin /> : null}
      {showVinAmountError ? <ErrorForTokensAmunt /> : null}
      {isMobileMenuOpen && width < 768 ? (
        <WrapperForMobileMenu>
          <CloseMobileMenu onClick={handleMobleMenuClose}>X</CloseMobileMenu>
          <UserControlPanel>
            <IconWithTextArea>
              <SvgBox>
                <HomeForDashboard />
              </SvgBox>
              <TitlesForControlPanel
                onClick={handleDashboard}
                style={{ color: openPanel === 0 ? "black" : "lightGray" }}
              >
                Dashboard
              </TitlesForControlPanel>
            </IconWithTextArea>
            <IconWithTextArea>
              <SvgBox>
                <PersonForDashboard />
              </SvgBox>
              <TitlesForControlPanel
                style={{ color: openPanel === 1 ? "black" : "lightGray" }}
                onClick={handleProfileSetting}
              >
                Profile Settings
              </TitlesForControlPanel>
            </IconWithTextArea>
            <IconWithTextArea>
              <SvgBox>
                <PaymentForDashboard />
              </SvgBox>
              <TitlesForControlPanel
                style={{ color: openPanel === 2 ? "black" : "lightGray" }}
                onClick={handlePayment}
              >
                Payment Method
              </TitlesForControlPanel>
            </IconWithTextArea>
            <IconWithTextArea>
              <SvgBox>
                <OrderHistoryForDashboard />
              </SvgBox>
              <TitlesForControlPanel
                style={{ color: openPanel === 3 ? "black" : "lightGray" }}
                onClick={handleOrderHistory}
              >
                Add Tokens
              </TitlesForControlPanel>
            </IconWithTextArea>
            <IconWithTextArea>
              <SvgBox>
                <CallForDashboard />
              </SvgBox>
              <TitlesForControlPanel
                style={{ color: openPanel === 4 ? "black" : "lightGray" }}
                onClick={handleContactSupport}
              >
                Contact Support
              </TitlesForControlPanel>
            </IconWithTextArea>
            <IconWithTextArea>
              <SvgBox>
                <SignOutForDashboard />
              </SvgBox>
              <TitlesForControlPanel onClick={handleSignOut}>
                Sign Out
              </TitlesForControlPanel>
            </IconWithTextArea>
          </UserControlPanel>
        </WrapperForMobileMenu>
      ) : null}
      {openAddCard ? <AddCard /> : null}
      <MainWrapper
        style={{
          filter:
            openAddCard || blurMainWrapper || showVinAmountError
              ? "blur(9px)"
              : "none",
        }}
      >
        <Hero />
        <DashVinWrapper>
          <VinInfoArea>
            <VinNumberDetailsBox>
              <VinAmountBox>{tokens} Vin Check Left</VinAmountBox>
              <ArrowBox>
                <svg
                  width="614"
                  height="24"
                  viewBox="0 0 614 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M613.062 13.0589C613.647 12.4722 613.646 11.5224 613.059 10.9376L603.497 1.40738C602.91 0.822556 601.961 0.824118 601.376 1.41087C600.791 1.99762 600.793 2.94736 601.379 3.53218L609.879 12.0035L601.407 20.5027C600.823 21.0895 600.824 22.0392 601.411 22.624C601.998 23.2089 602.947 23.2073 603.532 22.6206L613.062 13.0589ZM1.00247 14.5049L612.002 13.5L611.997 10.5L0.997533 11.5049L1.00247 14.5049Z"
                    fill="#B1ADAD"
                  />
                </svg>
              </ArrowBox>
              <RefillBox onClick={handleStripePayment}>
                Refill Vin Amount
              </RefillBox>
            </VinNumberDetailsBox>
            <VinInputWithButtonBox>
              <VinInputBox>
                <VinInput
                  type="text"
                  id="vin"
                  name="vin"
                  minLength={17}
                  maxLength={17}
                  style={{ textTransform: "uppercase", textAlign: "center" }}
                  placeholder="Search By Vin"
                  onChange={handleVinChange}
                />
              </VinInputBox>
              <ButtonArea>
                <InputButton
                  onClick={() => {
                    handleVinSubmit();
                    handleDecodeVin();
                  }}
                >
                  Buy Now >
                </InputButton>
              </ButtonArea>
            </VinInputWithButtonBox>
          </VinInfoArea>
          <DashboardArea>
            <UserControlPanel>
              {width < 750 ? (
                <MobileViewUserControl />
              ) : (
                <>
                  <IconWithTextArea>
                    <SvgBox>
                      <HomeForDashboard />
                    </SvgBox>
                    <TitlesForControlPanel
                      onClick={handleDashboard}
                      style={{ color: openPanel === 0 ? "black" : "lightGray" }}
                    >
                      Dashboard
                    </TitlesForControlPanel>
                  </IconWithTextArea>
                  <IconWithTextArea>
                    <SvgBox>
                      <PersonForDashboard />
                    </SvgBox>
                    <TitlesForControlPanel
                      style={{ color: openPanel === 1 ? "black" : "lightGray" }}
                      onClick={handleProfileSetting}
                    >
                      Profile Settings
                    </TitlesForControlPanel>
                  </IconWithTextArea>
                  <IconWithTextArea>
                    <SvgBox>
                      <PaymentForDashboard />
                    </SvgBox>
                    <TitlesForControlPanel
                      style={{ color: openPanel === 2 ? "black" : "lightGray" }}
                      onClick={handlePayment}
                    >
                      Payment Method
                    </TitlesForControlPanel>
                  </IconWithTextArea>
                  <IconWithTextArea>
                    <SvgBox>
                      <OrderHistoryForDashboard />
                    </SvgBox>
                    <TitlesForControlPanel
                      style={{ color: openPanel === 3 ? "black" : "lightGray" }}
                      onClick={handleOrderHistory}
                    >
                      Add Tokens
                    </TitlesForControlPanel>
                  </IconWithTextArea>
                  <IconWithTextArea>
                    <SvgBox>
                      <CallForDashboard />
                    </SvgBox>
                    <TitlesForControlPanel
                      style={{ color: openPanel === 4 ? "black" : "lightGray" }}
                      onClick={handleContactSupport}
                    >
                      Contact Support
                    </TitlesForControlPanel>
                  </IconWithTextArea>
                  <IconWithTextArea>
                    <SvgBox>
                      <SignOutForDashboard />
                    </SvgBox>
                    <TitlesForControlPanel onClick={handleSignOut}>
                      Sign Out
                    </TitlesForControlPanel>
                  </IconWithTextArea>{" "}
                </>
              )}
            </UserControlPanel>
            <VinResultArea>
              <DashboardHeading>
                {" "}
                {openPanel === 0 ? (
                  "Dashboard"
                ) : openPanel === 1 ? (
                  "Profile Settings"
                ) : openPanel === 2 ? (
                  "Payment Method"
                ) : openPanel === 3 ? (
                  "Add Tokens"
                ) : openPanel === 4 ? (
                  "Contact Support"
                ) : openPanel === 5 ? (
                  <SignOutForDashboard />
                ) : (
                  "error"
                )}
              </DashboardHeading>
              {openPanel === 0 ? (
                <VinResult>
                  <VinResultBox>
                    {vinResult.map((result) => (
                      <VinResultItem key={result.title}>
                        <VinNumber>{result.vin}</VinNumber>
                        <YearForMake>{result.year}</YearForMake>
                        <MakeForVin>{result.make}</MakeForVin>
                        <VinPurchaseDate>{result.date}</VinPurchaseDate>
                      </VinResultItem>
                    ))}
                  </VinResultBox>
                </VinResult>
              ) : openPanel === 1 ? (
                <ProfileSettings />
              ) : openPanel === 2 ? (
                <PaymentMethod />
              ) : openPanel === 3 ? (
                <AddTokens />
              ) : openPanel === 4 ? (
                <ContactSupport />
              ) : openPanel === 5 ? (
                <SignOutForDashboard />
              ) : (
                <VinResult />
              )}
            </VinResultArea>
          </DashboardArea>
        </DashVinWrapper>
      </MainWrapper>
    </>
  );
};

export default UserPanel;
